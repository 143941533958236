import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Container from "./Container2";
import { PROJECTS_PER_PAGE } from "../lib/constants";
import { getProjects } from "../lib/api";
import AnimLoadingIcon from "./icons/AnimLoadingIcon";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import HomeImage from "./HomeImage";
import ImageViewer from "./ImageViewer";

export default function ProjectsArchive({ projects, ...props }) {
  const [isImage, setOpenedImage] = React.useState(null);
  const [isProjectCategory, setProjectCategory] = React.useState(null); // deveria ser apagado
  const [posts, setPosts] = React.useState(projects?.edges);
  const [hasMore, setHasMore] = React.useState(null);
  const [lastCursor, setLastCursor] = React.useState(null);

  console.log("====");
  console.log("ProjectsArchive");
  console.log(projects, props);

  if (!projects?.edges) {
    console.error('No Projects Available in <ProjectsArchive>.')
    return <></>
  };

  // Get more posts for the infinite scroll
  const getMorePost = async () => {
    console.log("getMorePost");
    const newQuerySlug = props.params?.slug ? props.params?.slug : null;
    const res = await getProjects(newQuerySlug, PROJECTS_PER_PAGE, lastCursor);

    setLastCursor(res.projects.pageInfo.endCursor);
    setHasMore(res.projects.pageInfo.hasNextPage);
    setPosts((post) => [...post, ...res.projects.edges]);
  };

  // Only on first run
  if (hasMore == null) {
    console.log("hasMore");
    setLastCursor(projects?.pageInfo.endCursor);
    setHasMore(projects?.pageInfo.hasNextPage);
  }

  return (
    <>
      <Container>
        <InfiniteScroll
          className={"pb-6 md:pb-12"}
          dataLength={posts.length}
          next={getMorePost}
          hasMore={hasMore}
          loader={
            <div className="h-48 md:h-80 w-full flex justify-center items-center">
              <AnimLoadingIcon />
            </div>
          }
          endMessage={<></>}
        >
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 0: 1, 768: 2, 1024: 3 }}
          >
            <Masonry gutter={"1rem"}>
              {posts.map((item, i) => {
                return (
                  <HomeImage
                    key={i}
                    projectData={item.node}
                    visible={
                      item.node.terms.edges[0].node.slug == isProjectCategory ||
                      isProjectCategory == null
                    }
                    projectThumbnail={item.node.featuredImage?.node}
                    onClickCallback={setOpenedImage}
                  />
                );
              })}
            </Masonry>
          </ResponsiveMasonry>
        </InfiniteScroll>
      </Container>
      <ImageViewer projectData={isImage} onCloseCallback={setOpenedImage} />
    </>
  );
}
